.about {
	display: flex;
	flex-direction: column;
}

.about__collection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.about__content {
	margin: auto;
}

.about__service {
	max-width: 50%;
	display: flex;
	flex-direction: row;
}

.content {
	margin: auto;
}

.content__title {
	margin: 75px auto auto auto;
	font-style: normal;
	font-weight: 500;
	font-size: 55px;
	line-height: 110%;
	letter-spacing: 0.02em;
	color: black;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.content__text {
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
}

.content__subtitle {
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
}

.content__subheading {
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	letter-spacing: 0.02em;
	color: #000000;
}

.content__img {
	margin: auto 5px auto 5px;
	max-width: 40%;
	max-height: 80%;
}

@media screen and (min-width: 320px) {
	.about {
		max-width: 320px;
		min-height: 320px;
		margin: auto auto 25px auto;
	}

	.about__collection {
		font-size: 13px;
		max-width: 100%;
		margin: 30px 10px auto 10px;
	}

	.about__content {
		max-width: 100%;
		margin: auto 10px auto 10px;
	}

	.about__service {
		font-size: 13px;
		max-width: 100%;
		margin: auto 10px auto 10px;
	}

	.content__title {
		max-width: 200px;
	}

	.content__text {
		font-size: 13px;
	}

	.content__subtitle {
		font-size: 17px;
		margin: 75px 0 auto 0;
		max-width: 320px;
		max-height: 320px;
	}

	.content__subheading {
		font-size: 17px;
		margin: 30px 10px auto 10px;
	}

	.content__img {
		display: none;
	}
}

@media screen and (min-width: 768px) {
	.about {
		max-width: 700px;
		min-height: 474px;
		margin: 75px auto auto auto;
	}

	.about__collection {
		margin: auto 0 auto auto;
	}

	.about__content {
		margin: auto 10px auto 10px;
	}

	.about__service {
		margin: auto auto auto 0;
	}

	.content__title {
		max-width: 630px;
	}

	.content__text {
		font-size: 17px;
	}

	.content__subtitle {
		font-size: 20px;
		max-width: 700px;
		max-height: 474px;
	}

	.content__subheading {
		font-size: 20px;
		margin: 50px auto auto 0;
	}

	.content__img {
		display: inline;
	}
}

@media screen and (min-width: 1024px) {
	.about {
		max-width: 880px;
		min-height: 474px;
	}

	.content__title {
		max-width: 810px;
	}

	.content__text {
		font-size: 20px;
	}

	.content__subtitle {
		font-size: 25px;
		max-width: 880px;
		max-height: 474px;
	}

	.content__subheading {
		font-size: 25px;
	}
}

@media screen and (min-width: 1280px) {
	.about {
		max-width: 1110px;
		min-height: 474px;
	}

	.content__title {
		max-width: 1030px;
	}

	.content__subtitle {
		max-width: 1110px;
		max-height: 474px;
	}
}