.navigation {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 5;
	top: 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: opacity .5s linear, visibility .5s linear;
	background-color: #ffffff;
}

.navigation_open {
	visibility: visible;
	opacity: 1;
}

.navigation__close {
	background-image: url(../../images/close.svg);
	width: 25px;
	height: 25px;
	text-align: center;
	border: none;
	background-color: #ffffff;
	cursor: pointer;
}

.navigation__menu {
	color: #000000;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	display: inline;
	cursor: pointer;
	text-decoration: none;
}

@media screen and (min-width: 320px) {
	.navigation {
		min-width: 320px;
		/*min-height: 780px;*/
	}

	.navigation__close {
		margin: 13px 13px 15px auto;
	}

	.navigation__menu {
		max-width: 300px;
		min-height: 31px;
		margin: 0 auto 15px auto;
	}

	.navigation__menu:hover {
		border-bottom: 2px solid #6E9C9F;
		min-height: 29px;
	}
}