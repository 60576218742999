.cart__caption {
	margin: 75px auto auto 0;
	font-style: normal;
	font-weight: 500;
	font-size: 55px;
	line-height: 110%;
	letter-spacing: 0.02em;
	color: black;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.cart__title {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
}

@media screen and (min-width: 320px) {
	.cart__checkout_text {
		margin: auto 0;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.cart__checkout_cost {
		margin: auto auto auto 69px;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.cart__synopsis {
		margin: 50px auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		text-align: left;
		min-width: 287px;
		min-height: 24px;
	}

	.cart__buy {
		margin-bottom: 75px;
	}

	.cart {
		margin: auto;
		display: flex;
		flex-direction: column;
		max-width: 320px;
	}

	.cart__header {
		display: none;
	}

	.cart__header-left {
		margin: 0;
		display: flex;
		flex-direction: column;
		max-width: 200px;
		/*max-height: 120px;*/
		justify-content: space-between;
	}

	.cart__line {
		min-width: 300px;
		border: 1px solid #CCCCCC;
		margin: 25px auto;
	}

	.cart__coupon {
		margin: 45px auto 0 0;
		min-height: 74px;
		display: flex;
		flex-direction: column;
	}

	.cart__button-coupon {
		margin: 15px auto auto auto;
		width: 268px;
		height: 68px;
		background: none;
		font-style: normal;
		font-weight: 400;
		font-size: 17px;
		line-height: 138.9%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #6E9C9F;
		cursor: pointer;
		border: 1px solid #6E9C9F;
	}

	.cart__button-coupon:hover {
		color: #ffffff;
		background: #6E9C9F;
	}

	.cart__code {
		margin: auto 0;
		padding: 0 15px;
		width: 238px;
		height: 68px;
		border: none;
		border-bottom: 1px solid #AFAFAF;
		text-align: left;
	}

	.cart__code:focus {
		border-bottom: 1px solid #ffffff;
		outline: 1px solid #6E9C9F;
	}
}

@media screen and (min-width: 768px) {
	.cart__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		max-height: 28px;
		margin-top: 55px;
		margin-bottom: 26px;
		margin-left: 90px;
	}

	.cart__header-left {
		display: flex;
		flex-direction: row;
		min-width: 350px;
		justify-content: space-around;
	}

	.cart {
		min-width: 722px;
	}

	.cart__line {
		min-width: 722px;

		margin: 0 auto 49px auto;
	}

	.cart__coupon {
		margin: 72px auto 0 0;
		flex-direction: row;
	}

	.cart__button-coupon {
		margin: auto 0 auto 16px;
		width: 268px;
	}

	.cart__code {
		width: 255px;
	}

	.cart__buy {
		margin: 88px 0 auto auto;
		display: flex;
		flex-direction: column;
		min-width: 538px;
		min-height: 120px;
	}

	.cart__synopsis {
		margin: 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		text-align: left;
		min-width: 287px;
		min-height: 24px;
	}

	.cart__checkout {
		margin: auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		text-align: left;
		min-width: 529px;
		min-height: 68px;
	}

	.cart__checkout_text {
		margin: auto 0;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.cart__checkout_cost {
		margin: auto auto auto 69px;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.cart__button {
		max-width: 236px;
		margin: 0;
	}

}

@media screen and (min-width: 1024px) {
	.cart {
		min-width: 880px;
	}

	.cart__header {
		margin-top: 75px;
		margin-bottom: 26px;
		margin-left: 90px;
	}

	.cart__header-left {
		display: flex;
		flex-direction: row;
		min-width: 400px;
		justify-content: space-around;
	}

	.cart__line {
		min-width: 880px;
	}

}

@media screen and (min-width: 1280px) {
	.cart {
		min-width: 1110px;
	}

	.cart__header {
		margin-top: 75px;
		margin-bottom: 26px;
		margin-left: 90px;
	}

	.cart__header-left {
		display: flex;
		flex-direction: row;
		min-width: 534px;
		justify-content: space-between;
	}

	.cart__title {
		max-width: 223px;
	}

	.cart__line {
		min-width: 1110px;
	}


}