@media screen and (min-width: 320px) {
	.cart__delete {
		margin-left: 5px;
		min-width: 13px;
		min-height: 13px;
		background-image: url('../../../images/del.svg');
		background-color: #FFFFFF;
		border: none;
		cursor: pointer;
		text-align: center;
		padding: 0;
	}

	.cart__delete:hover {
		box-shadow: 0px 0px 7px #6E9C9F;
	}

	.cart__img {
		margin: auto 5px;
		width: 75px;
		height: 99px;
	}

	.cart__group {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.cart__product {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		max-width: 200px;
		max-height: 179px;
		margin-bottom: 15px;
	}

	.cart__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		min-width: 200px;
	}

	.cart__unit {
		margin: auto 0;
		padding: 0;
		width: 25px;
		height: 25px;
		border: 1px solid #AFAFAF;
		text-align: center;
		font-size: 20px;
	}

	.cart__unit:focus {
		outline: 1px solid #6E9C9F;
	}

	.cart__description-text {
		margin: 3px auto;
		text-align: left;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.cart__price {
		display: none;
	}
	.cart__price-total {
		margin: auto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}
}

@media screen and (min-width: 768px) {
	.cart__description {
		min-width: 150px;
		min-height: 65px;
	}

	.cart__description-text {
		font-size: 20px;
	}

	.cart__img {
		margin: auto 24px auto 45px;
		width: 136px;
		height: 180px;
	}

	.cart__info {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		min-width: 500px;
	}

	.cart__product {
		align-items: center;
	}

	.cart__price {
		display: flex;
		margin: auto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.cart__price-total {
		margin: auto 0 auto auto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.cart__price {
		margin: auto auto auto 25px;
	}

	.cart__price-total {
		margin: auto 25px auto auto ;
	}
}

@media screen and (min-width: 1024px) {
	.cart__description {
		min-width: 223px;
	}

	.cart__info {
		min-width: 630px;
	}

	.cart__price {
		margin: auto auto auto 50px;
	}
}

@media screen and (min-width: 1280px) {
	.cart__info {
		min-width: 890px;
	}
	.cart__product {
		display: flex;
		flex-direction: row;
		align-items: center;
		min-width: 1112px;
		max-height: 179px;
		margin-bottom: 15px;
		justify-content: space-between;
	}

	.cart__description {
		margin: auto auto auto 0;

		max-width: 223px;
		min-height: 24px;
	}

	.cart__description-text {
		margin: auto;
		text-align: left;
	}



	.cart__price {
		margin: auto auto auto 0;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	/*.cart__unit {*/
	/*	margin: auto 0;*/
	/*	padding: 0;*/
	/*	width: 47px;*/
	/*	height: 47px;*/
	/*	border: 1px solid #AFAFAF;*/
	/*	text-align: center;*/
	/*	font-size: 20px;*/
	/*}*/

	/*.cart__unit:focus {*/
	/*	outline: 1px solid #6E9C9F;*/
	/*}*/



}