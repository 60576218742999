@media screen and (min-width: 320px) {
	.switch {
		display: inline-block;
		position: relative;
		width: 40px;
		height: 24px;
	}

	.switch__input {
		height: 0;
		width: 0;
		opacity: 0;
	}

	.switch__slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		border-radius: 24px;
		background-color: #6E9C9F;
		transition: .4s;
	}

	.switch__slider::before {
		content: "";
		position: absolute;
		cursor: pointer;
		left: 4px;
		bottom: 4px;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background-color: #FFFFFF;
		transition: .4s;
	}

	.switch__input:focus + .switch__slider {
		box-shadow: 0 0 1px #6E9C9F;
	}

	.switch__input:checked + .switch__slider {
		background-color: #6E9C9F;
	}

	.switch__input:checked + .switch__slider::before {
		transform: translateX(16px);
		-ms-transform: translateX(16px);
	}

	.order__checkbox {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		min-width: 300px;
	}

	.cart__caption-order {
		margin: 75px auto auto auto;
		max-width: 300px;
		font-style: normal;
		font-weight: 500;
		font-size: 45px;
		line-height: 110%;
		letter-spacing: 0.02em;
		color: black;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.order__list {
		margin: 75px auto auto auto;
		max-width: 320px;
		min-height: 1072px;
		display: flex;
		flex-direction: column;
		justify-items: stretch;
		align-items: stretch;
		gap: 50px;
	}

	.order__title {
		margin: 0;
		min-width: 145px;
		min-height: 35px;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.order__radio-pay {
		min-width: 100px;
		font-size: 20px;
	}

	.order__radio-pay-text {
		font-size: 20px;
		max-width: 100px;
		font-style: normal;
		font-weight: bold;
		font-size: 19px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.order__title-order {
		margin: 0;
	}

	.order__input{
		margin: 0;
		padding: 0 15px;
		max-width: 290px;
		min-height: 40px;
		border: none;
		border-bottom: 1px solid #AFAFAF;
		text-align: left;
		background-color: #FFFFFF;
	}

	.order__input:focus {
		border-bottom: 1px solid #ffffff;
		outline: 1px solid #6E9C9F;
	}

	.order__details {
		margin: auto auto auto 10px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 47px;
		max-width: 300px;
		min-height: 272px;
	}

	.order__input-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px;
		gap: 15px;
		max-width: 290px;
		min-height: 190px;
	}

	.order__info {
		margin: auto auto auto 10px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 47px;
		min-width: 300px;
		min-height: 276px;
	}

	.order__info-centr {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		gap: 73px;
		min-width: 255px;
		min-height: 194px;
	}

	.order__info-left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 15px;
		min-width: 123px;
		min-height: 194px;
	}

	.order__info-right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 15px;
		min-width: 70px;
		min-height: 194px;
	}

	.order__caption {
		margin: 0;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.order__product {
		margin: 0;
		min-height: 24px;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.order__caption-bold {
		font-weight: bold;
	}

	.order__radio {
		margin: auto auto auto 10px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 47px;
		max-width: 300px;
		min-height: 272px;
	}

	.order__radio-input {
		cursor: pointer;
	}

	.order__addres {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 15px;
		max-width: 320px;
		min-height: 300px;
	}

	.active {
		display: none;
	}

	.product__button {
		margin: 0;
	}

	.product__button:hover {
		background-color: #6E9C9F;
		color: #FFFFFF;
	}
}

@media screen and (min-width: 768px) {
	.switch {
		margin: auto 15px auto auto;
		display: inline-block;
		position: relative;
		width: 60px;
		height: 34px;
	}

	.switch__input {
		height: 0;
		width: 0;
		opacity: 0;
	}

	.switch__slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		border-radius: 34px;
		background-color: #6E9C9F;
		transition: .4s;
	}

	.switch__slider::before {
		content: "";
		position: absolute;
		cursor: pointer;
		left: 4px;
		bottom: 4px;
		height: 26px;
		width: 26px;
		border-radius: 50%;
		background-color: #FFFFFF;
		transition: .4s;
	}

	.switch__input:focus + .switch__slider {
		box-shadow: 0 0 1px #6E9C9F;
	}

	.switch__input:checked + .switch__slider {
		background-color: #6E9C9F;
	}

	.switch__input:checked + .switch__slider::before {
		transform: translateX(26px);
		-ms-transform: translateX(26px);
	}

	/*.order__checkbox {*/
	/*	min-width: 320px;*/
	/*}*/

	.cart__caption-order {
		margin: 75px auto auto 0;
		min-width: 700px;
		font-size: 55px;
	}

	.order__list {
		margin: 75px 0 auto 0;
		min-width: 722px;
		min-height: 1072px;
		display: grid;
		justify-items: stretch;
		align-items: stretch;
		gap: 100px 50px;
		grid-template-columns: 350px 255px;
		grid-template-rows: 275px 350px 250px;
	}

	.order__title {
		margin: 0;
		min-width: 145px;
		min-height: 35px;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.order__radio-pay {
		min-width: 100px;
		font-size: 20px;
	}

	.order__title-order {
		margin: 0;
	}

	.order__input{
		margin: 0;
		padding: 0 15px;
		width: 320px;
		min-height: 40px;
		border: none;
		border-bottom: 1px solid #AFAFAF;
		text-align: left;
		background-color: #FFFFFF;
	}

	.order__input:focus {
		border-bottom: 1px solid #ffffff;
		outline: 1px solid #6E9C9F;
	}

	.order__details {
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 47px;
		min-width: 350px;
		min-height: 272px;
	}

	.order__input-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px;
		gap: 15px;
		min-width: 350px;
		min-height: 190px;
	}

	.order__info {
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 47px;
		max-width: 300px;
		min-height: 276px;
	}

	.order__info-centr {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		gap: 73px;
		min-width: 255px;
		min-height: 194px;
	}

	.order__info-left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 15px;
		min-width: 123px;
		min-height: 194px;
	}

	.order__info-right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 15px;
		min-width: 70px;
		min-height: 194px;
	}

	.order__caption {
		margin: 0;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.order__product {
		margin: 0;
		min-height: 24px;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.order__caption-bold {
		font-weight: bold;
	}

	.order__radio {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 47px;
		min-width: 320px;
		min-height: 272px;
	}

	.order__radio-input {
		cursor: pointer;
	}

	.order__addres {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 15px;
		min-width: 350px;
		/*min-height: 422px;*/
	}

	.active {
		display: none;
	}

	.product__button {
		margin: 0;
	}

	.product__button:hover {
		background-color: #6E9C9F;
		color: #FFFFFF;
	}
}

@media screen and (min-width: 1024px) {
	.order__list {
		gap: 100px 250px;
	}
}

@media screen and (min-width: 1280px) {

}