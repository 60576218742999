@media screen and (min-width: 320px) {
	.order-received {
		margin: 75px auto 75px auto;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		min-width: 320px;
		min-height: 96px;
	}

	.order-received__title {
		margin: 50px auto;
		min-width: 300px;
	}

	.order__product {
		margin: 0 auto 50px auto;
		max-width: 300px;
	}
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1024px) {
	.order-received {
		margin: 150px auto 200px auto;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		min-width: 880px;
		min-height: 96px;
	}
}

@media screen and (min-width: 1280px) {
	.order-received {
		margin: 175px auto 275px auto;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		min-width: 1110px;
		min-height: 96px;
	}
}