@font-face {
  src: url(./fonts/CeraPro-Regular.ttf) format('truetype'),
    url(./fonts/CeraPro-Regular.eot) format('eot'),
    url(./fonts/CeraPro-Regular.woff) format('woff');
  /* url(./fonts/Raleway-MediumItalic.woff2) format('woff2'); */
  font-family: 'CeraPro';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url(./fonts/Raleway-Medium.ttf) format('truetype'),
    url(./fonts/Raleway-Medium.eot) format('eot'),
    url(./fonts/Raleway-Medium.woff) format('woff'),
    url(./fonts/Raleway-Medium.woff2) format('woff2');
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
}
/*  @font-face {*/
/*  src: url(./fonts/HelveticaNeueCyr-Black.eot) format('eot'),*/
/*    url(./fonts/HelveticaNeueCyr-Black.woff) format('woff'),*/
/*    url(./fonts/HelveticaNeueCyr-Black.woff2) format('woff2');*/
/*  font-family: 'Helvetica';*/
/*  font-weight: 900;*/
/*}*/

@font-face {
 src: url(./fonts/HelveticaNeueCyr-Medium.eot) format('eot'),
   url(./fonts/HelveticaNeueCyr-Medium.woff) format('woff'),
   url(./fonts/HelveticaNeueCyr-Medium.woff2) format('woff2');
 font-family: 'Helvetica';
 font-weight: 500;
}

/* @font-face {
  src: url(./fonts/HelveticaNeueCyr-Thin.eot) format('eot'),
    url(./fonts/HelveticaNeueCyr-Thin.woff) format('woff'),
    url(./fonts/HelveticaNeueCyr-Thin.woff2) format('woff2');
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
} */
