.register {
	margin: auto;
}

.register__header {
	margin: auto;
	display: flex;
	flex-direction: column;
}

.logo_login {
	margin: auto;
}

.register__welcome {
	color: #000000;
	font-style: normal;
	font-weight: 500;
}

.register__form {
	margin: auto;
	display: flex;
	flex-direction: column;
}

.register__info {
	display: flex;
	flex-direction: column;
}

.register__text {
	font-style: normal;
	font-weight: 400;
	color: #000000;
	font-size: 10px;
	line-height: 12px;
	margin: 0 auto 10px 0;
}

.register__input {
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #000000;
	margin-bottom: 20px;
	padding: 15px;
	background: #F9F9F9;
	border-radius: 8px;
	border: none;
}

.register__password {
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #EE3465;
	margin: auto;
	padding: 15px;
	background: #F9F9F9;
	border-radius: 8px;
	border: none;
}

.register__error {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #EE3465;
	padding: 0;
	display: none;
}

.rigister__error_active {
	display: flex;
}

.register__button {
	margin: auto;
}

.register__signup {
	background: #6E9C9F;
	border-radius: 3px;
	color: #FFFFFF;
	font-style: normal;
	font-weight: 500;
	text-align: center;
	border: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.register__question {
	color: #000000;
	font-style: normal;
	font-weight: 400;
	text-align: center;
}

.register__signin {
	color: #6E9C9F;
	font-style: normal;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
}

.register__block {
	display: flex;
}

@media screen and (min-width: 320px) {
	.register {
		max-width: 100vw;
		min-height: 780px;
	}

	.register__header {
		max-width: 100vw;
		min-height: 53px;
	}

	.logo_login {
		margin: 56px auto 50px auto;
	}

	.register__welcome {
		margin: 80px auto 80px auto;
		min-width: 260px;
		min-height: 29px;
		font-size: 24px;
		line-height: 29px;
	}

	.register__form {
		max-width: 260px;
		min-height: 266px;
	}

	.register__info {
		max-width: 260px;
		min-height: 68px;
	}

	.register__text {
		min-width: 260px;
		min-height: 12px;
	}

	.register__input {
		min-width: 230px;
		min-height: 16px;
	}

	.register__password {
		min-width: 230px;
		min-height: 16px;
	}

	.register__error {
		margin: 10px auto auto auto;
		min-width: 260px;
		min-height: 12px;
	}

	.register__button {
		max-width: 260px;
		min-height: 266px;
	}

	.register__signup {
		margin: 157px auto 14px auto;
		min-width: 260px;
		min-height: 45px;
		font-size: 12px;
		line-height: 15px;
	}

	.register__question {
		margin: 14px 7px 30px auto;
		min-width: 145px;
		min-height: 15px;
		font-size: 12px;
		line-height: 15px;
	}

	.register__signin {
		margin: 14px auto 30px 0;
		min-width: 36px;
		min-height: 15px;
		font-size: 12px;
		line-height: 15px;
	}
}

@media screen and (min-width: 768px) {
	.register {
		min-height: 1024px;
	}

	.register__header {
		min-height: 179px;
		max-width: 396px;
	}

	.logo_login {
		margin: 232px auto 40px 0;
	}

	.register__welcome {
		min-width: 396px;
		margin: 80px auto 40px auto;
	}

	.register__form {
		min-width: 396px;
	}

	.register__info {
		min-width: 396px;
	}

	.register__text {
		min-width: 396px;
	}

	.register__input {
		min-width: 366px;
	}

	.register__password {
		min-width: 366px;
	}

	.register__error {
		min-width: 396px;
	}

	.register__button {
		min-width: 396px;
	}

	.register__signup {
		min-width: 396px;
		font-size: 14px;
		line-height: 17px;
		margin-top: 69px;
	}

	.register__question {
		margin: 16px 6px 232px auto;
		min-width: 169px;
		min-height: 17px;
		font-size: 14px;
		line-height: 17px;
	}

	.register__signin {
		margin: 16px auto 232px 0;
		min-width: 42px;
		min-height: 17px;
		font-size: 14px;
		line-height: 17px;
	}
}

@media screen and (min-width: 1280px) {
	.register {
		min-height: 700px;
	}

	.register__header {
		min-height: 217px;
	}

	.logo_login {
		margin: 70px auto 40px 0;
	}

	.register__question {
		margin: 16px 6px 70px auto;
	}

	.register__signin {
		margin: 16px auto 70px 0;
	}
}