.header {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin: auto;
	z-index: 6;
	background-color: white;
	max-width: 100vw;
	position: sticky;
	top: 0px;
}

.header__burger {
	margin: auto;
	width: 35px;
	height: 35px;
	background-image: url(../../images/burger1.png);
	border: 0;
	cursor: pointer;
}

.header__button {
	margin: 0;
}

.header__item {
	margin: auto;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 140%;
	color: black;
	text-decoration: none;
	border: none;
	cursor: pointer;
	background-color: #FFFFFF;
}

.header__item:hover {
	color: #6E9C9F;
}

.header__cart {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/cart.png) no-repeat;
	border: 0;
}

.header__cart:hover {
	background: url(../../images/cart1.png) no-repeat;
}

.full-cart {
	background: url(../../images/fullcart.png) no-repeat;
}

.full-cart:hover {
	background: url(../../images/fullcart1.png) no-repeat;
}

.header__search {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/search1.png) no-repeat;
	background-color: white;
	border: 0;
	cursor: pointer;
}

.header__search:hover {
	background: url(../../images/search.png) no-repeat;
}

.header__profile {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/user1.png) no-repeat;
	border: 0;
	cursor: pointer;
}

.header__profile:hover {
	background: url(../../images/user.png) no-repeat;
}

.header__phone {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/phone1.png) no-repeat;
	border: 0;
}

.header__phone:hover {
	background: url(../../images/phone.png) no-repeat;
}

.header__logo {
	background-image: url(../../images/logo.png);
	color: aqua;
	display: flex;
	background-size: cover;
	margin: 0;
}

.header__dropdown {
	position: relative;
	display: inline-block;
}

.header__dropdown:hover .header__dropdown-content {
	display: block;
}

.header__dropdown:hover .header__dropdown-form {
	/*display: block;*/
	display: flex;
}

.header__dropdown-content {
	display: none;
	position: absolute;
	background-color: #FFFFFF;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px #6E9C9F;
	z-index: 1;
}

.header__dropdown-form {
	display: none;
	position: absolute;
	background-color: #FFFFFF;
	min-width: 200px;
	min-height: 30px;
	box-shadow: 0px 8px 16px 0px #6E9C9F;
	z-index: 1;

}

.header__content {
	margin: auto;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 140%;
	color: black;
	text-decoration: none;
	border: none;
	cursor: pointer;
	background-color: #FFFFFF;
	display: block;
	text-decoration: none;
	padding: 5px 10px;
}

.header__content:hover {
	color: #6E9C9F;
}

.header__search-film {
	margin: auto;
	border: none;
	outline: none;
	color: black;
	font-style: normal;
	font-weight: 500;
	display: block;
	text-decoration: none;
	padding: 5px 10px;
}

/* .header__search-film:focus {
	outline: 1px solid #6E9C9F;
	border-radius: 5px;
	padding: 5px;
} */

.header__search-button {
	margin: auto 0;
	background: #6E9C9F;
	min-width: 80px;
	min-height: 34px;
	background-image: url(../../images/glass.svg);
	border: none;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 15px;
}

@media screen and (min-width: 320px) {
	.header {
		min-width: 320px;
		min-height: 70px;
	}

	.header__burger {
		max-width: 70px;
		max-height: 70px;
		margin-right: 20px;
	}

	.header__button {
		display: none;
	}

	.header__item {
		max-width: 75px;
		min-height: 21px;
	}

	.header__cart {
		max-width: 75px;
		min-height: 21px;
	}

	.header__search {
		max-width: 75px;
		min-height: 21px;
	}

	.header__profile {
		max-width: 75px;
		min-height: 21px;
	}

	.header__phone {
		max-width: 75px;
		min-height: 21px;
	}

	.header__menu {
		display: none;
	}

	.header__logo {
		min-width: 133px;
		min-height: 70px;
	}
}

@media screen and (min-width: 768px) {
	.header {
		min-width: 100%;
	}

	.header__burger {
		display: none;
	}

	.header__button {
		max-width: 255px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	.header__menu {
		min-width: 400px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}

	.header__logo {
		min-width: 142px;
	}
}
/*
@media screen and (min-width: 1024px) {

}

@media screen and (min-width: 1280px) {

} */