.profile {
	margin: auto;
	display: flex;
	flex-direction: column;
}

.profile__greeting {
	font-style: normal;
	font-weight: 500;
	color: #000000;
}

.profile__info {
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.profile__text {
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	color: #000000;
	text-align: left;
}

.profile__text_input {
	text-align: right;
	border: none;
}

.profile__button {
	display: flex;
	flex-direction: column;
}

.profile__edit {
	color: #000000;
	font-style: normal;
	font-weight: 400;
	margin: 0 auto 16px auto;
	border: none;
	background: #FFFFFF;
	cursor: pointer;
	text-decoration: none;
}

.profile__edit_exit {
	color: #EE3465;
	text-decoration: none;
}

@media screen and (min-width: 320px) {
	.profile {
		max-width: 320px;
		min-height: 706px;
	}

	.profile__greeting {
		margin: 70px auto 80px auto;
		min-width: 204px;
		min-height: 29px;
		font-size: 24px;
		line-height: 29px;
	}

	.profile__form {
		margin: auto;
		min-width: 260px;
		min-height: 62px;
	}

	.profile__line {
		background: #E8E8E8;
		min-width: 260px;
		margin: 16px auto 17px auto;
	}

	.profile__button {
		margin: 379px auto 24px auto;
	}

	.profile__edit {
		font-size: 12px;
		line-height: 15px;
	}
}

@media screen and (min-width: 768px) {
	.profile {
		max-width: 768px;
		min-height: 950px;
	}

	.profile__greeting {
		margin: 96px auto 96px auto;
	}

	.profile__form {
		min-width: 410px;
		min-height: 58px;
	}

	.profile__line {
		min-width: 410px;
	}

	.profile__button {
		margin: 210px auto 253px auto;
	}

	.profile__edit {
		font-size: 13px;
		line-height: 16px;
	}
}

@media screen and (min-width: 1280px) {
	.profile {
		max-width: 1280px;
		min-height: 626px;
	}

	.profile__greeting {
		margin: 74px auto 123px auto;
	}

	.profile__form {
		min-height: 62px;
	}

	.profile__button {
		margin: 220px auto 54px auto;
	}
}