/*слайдер*/
.product__slider {
	margin: 20px auto auto auto;
	max-width: 536px;
	max-height: 729px;
	overflow: hidden;
	/*border: 10px solid #000;*/
}

.product__middle {
	position: sticky;
	/*top: 50%;*/
	/*left: 50%;*/
	/*transform: translate(-50%, -50%);*/
}

.product__navigation {
	position: absolute;
	bottom: 16px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
}

.product__bar {
	width: 30px;
	height: 8px;
	margin: 6px;
	cursor: pointer;
	background-color: #6E9C9F;
	/*opacity: .5;*/
	border-radius: 10px;
	transition: all 0.4s ease;
}

.product__bar:hover {
	opacity: .9;
	transform: scale(1.3);
}

.product__bar.selected {
	transform: scale(1.5);
}

input[name="r"] {
	position: absolute;
	visibility: hidden;
}

.product__slides {
	width: 400%;
	height: 100%;
	display: flex;
}

.product__slide {
	width: 25%;
	transition: all 0.6s ease;
}

#r1:checked ~ .s1 {
	margin-left: 0;
}

#r2:checked ~ .s1 {
	margin-left: -25%;
}

#r3:checked ~ .s1 {
	margin-left: -50%;
}

#r4:checked ~ .s1 {
	margin-left: -75%;
}

#r5:checked ~ .s1 {
	margin-left: -100%;
}

#r6:checked ~ .s1 {
	margin-left: -125%;
}

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
@media screen and (min-width: 320px) {
	.product {
		margin: auto;
		display: flex;
		flex-direction: column;
		max-width: 320px;
		min-height: 474px;
	}

	.product__title {
		margin: auto 10px auto 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-width: 300px;
		min-height: 800px;
	}

	.product__sizes {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 90%;
	}

	.product__size {
		/* margin: auto; */
		/* border: 1px solid black; */
		/* color: black; */
		/* background-color: white; */
		/* cursor: pointer; */
	}

	.product__size:hover {
		/* color: white; */
		/* background-color: black; */
	}

	.product__size.unavailable-size {
		background-image: linear-gradient(to bottom right, transparent 45%, #6E9C9F, transparent 54%);
		/* margin: auto; */
	}

	/* .product__size.unavailable-size:hover {
		color: white;
		background-color: black;
		background-image: linear-gradient(to bottom right, transparent 45%, #6E9C9F, transparent 54%);
	} */

	.product__colors {
		margin: 20px 0 15px 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		max-width: 90%;
	}

	.product__color_text {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.product__color {
		margin: 0;
		border-radius: 50%;
		/* border: 4mm ridge #6E9C9F; */
		color: white;
		width: 35px;
		height: 35px;
		cursor: pointer;
		position: relative;
		display: inline-block;
	}

	.product__color_one {
		border: 2px solid #6E9C9F;
		background-color: black;
	}

	.product__color .product__color_text::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	.product__color:hover .product__color_text {
		visibility: visible;
		opacity: .7;
	}

	.product__color_two {
		border: 2px solid #6E9C9F;
		background-color: #bf8a3d;
	}

	.product__color_three {
		border: 2px solid #6E9C9F;
		background-color: #fffff0;
	}

	.product__color_four {
		border: 2px solid #6E9C9F;
		background-color: #d1bc8a;
	}

	.product__color_five {
		border: 2px solid #6E9C9F;
		background-color: #fffdd0;
	}

	.product__color_six {
		border: 2px solid #6E9C9F;
		background-color: #654321;
	}

	.product__color_seven {
		border: 2px solid #6E9C9F;
		background-color: white;
	}

	.product__color_eight {
		border: 2px solid #6E9C9F;
		background-color: beige;
	}

	.product__color_nine {
		border: 2px solid #6E9C9F;
		background-color: #fdfff5;
	}

	.product__color_ten {
		border: 2px solid #6E9C9F;
		background-color: #d7d7d7;
	}

	.product__color_eleven {
		border: 2px solid #6E9C9F;
		background-color: grey;
	}

	.product__color_twelve {
		border: 2px solid #6E9C9F;
		background-color: #af804f;
	}

	.product__color_thirteen {
		border: 2px solid #6E9C9F;
		background-color: #2c1a0b;
	}

	.product__color_fourteen {
		border: 2px solid #6E9C9F;
		background-color: #e0bca5;
	}

	.product__color_fifteen {
		border: 2px solid #6E9C9F;
		background-color: #45322e;
	}

	.product__color.unavailable-color {
		background-image: linear-gradient(to bottom right, transparent 45%, #6E9C9F, transparent 54%);
		text-decoration: line-through;
	}

	.product__color.unavailable-color .product__color_text {
		text-decoration: line-through;
	}

	.product__caption {
		margin: 15px auto 10px 0;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__price {
		display: flex;
		flex-direction: row;
		margin: auto auto auto 0;
		justify-content: flex-start;
	}

	.product__price-old {
		margin: auto;
		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 110%;
		letter-spacing: 0.02em;
		color: #000000;
		text-decoration: line-through;
		opacity: .5;
	}

	.product__price-new {
		margin: auto;
		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 110%;
		letter-spacing: 0.02em;
		color: #000000;
	}


	.product__name {
		margin: 20px auto 20px 0;
		font-style: normal;
		font-weight: 500;
		font-size: 40px;
		line-height: 110%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__descriptiontext {
		margin: auto 0;
		max-width: 280px;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__contact {
		margin: 25px auto 25px auto;
		width: 268px;
		height: 68px;
		background: #6E9C9F;
		font-style: normal;
		font-weight: 400;
		font-size: 17px;
		line-height: 138.9%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		cursor: pointer;
		border: 1px solid #6E9C9F;
	}

	.product__contact:hover {
		color: #6E9C9F;
		background: none;
	}

	/*слайдер*/
	.product__slider {
		max-width: 300px;
		max-height: 729px;
		overflow: hidden;
	}

	.product__middle {
		position: sticky;
	}

	.product__navigation {
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
	}

	.product__bar {
		width: 30px;
		height: 8px;
		margin: 6px;
		cursor: pointer;
		background-color: #6E9C9F;
		border-radius: 10px;
		transition: all 0.4s ease;
	}

	.product__img {
		max-width: 300px;
		max-height: 400px;
		object-fit: contain;
	}
}

@media screen and (min-width: 768px) {
	.product {
		margin: auto;
		display: flex;
		flex-direction: row;
		max-width: 700px;
		min-height: 474px;
	}

	.product__title {
		margin: auto auto auto 20px;
		display: flex;
		flex-direction: column;

		min-width: 300px;
		min-height: 800px;
	}

	.product__sizes {
		display: flex;
		flex-direction: row;
		max-width: 90%;
	}

	.product__size {
		/* margin: auto; */
		/* border: 1px solid black; */
		/* color: black; */
		/* background-color: white; */
		/* cursor: pointer; */
	}

	.product__size:hover {
		/* color: white; */
		/* background-color: black; */
	}

	.product__color_text {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.product__color {
		/* margin: auto; */
		border-radius: 50%;
		color: white;
		width: 35px;
		height: 35px;
		cursor: pointer;
		position: relative;
		display: inline-block;
	}

		.product__color .product__color_text::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	.product__color:hover .product__color_text {
		visibility: visible;
		opacity: .7;
	}

	.product__caption {
		margin: 25px auto 20px 0;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__name {
		margin: 20px auto 20px 0;
		font-style: normal;
		font-weight: 500;
		font-size: 40px;
		line-height: 110%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__descriptiontext {
		margin: auto 0;
		max-width: 300px;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__contact {
		margin: 25px auto 25px auto;
		width: 268px;
		height: 68px;
		background: #6E9C9F;
		font-style: normal;
		font-weight: 400;
		font-size: 17px;
		line-height: 138.9%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		cursor: pointer;
		border: 1px solid #6E9C9F;
	}

	.product__contact:hover {
		color: #6E9C9F;
		background: none;
	}

	/*слайдер*/
	.product__slider {
		max-width: 536px;
		max-height: 729px;
		overflow: hidden;
	}

	.product__middle {
		position: sticky;
	}

	.product__navigation {
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
	}

	.product__bar {
		width: 30px;
		height: 8px;
		margin: 6px;
		cursor: pointer;
		background-color: #6E9C9F;
		border-radius: 10px;
		transition: all 0.4s ease;
	}

	.product__img {
		max-width: 380px;
		max-height: 510px;
		object-fit: contain;
	}
}

@media screen and (min-width: 1024px) {
	.product {
		margin: auto;
		display: flex;
		flex-direction: row;
		max-width: 880px;
		min-height: 474px;
	}

	.product__title {
		margin: auto auto auto 50px;
		display: flex;
		flex-direction: column;
		min-width: 347px;
		min-height: 800px;
	}

	.product__sizes {
		display: flex;
		flex-direction: row;
		max-width: 70%;
	}

	.product__size {
		/* margin: auto; */
		/* border: 1px solid black; */
		/* color: black; */
		/* background-color: white; */
		/* cursor: pointer; */
	}

	.product__size:hover {
		/* color: white; */
		/* background-color: black; */
	}

	.product__colors {
		max-width: 70%;
	}

	.product__color_text {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.product__color {
		/* margin: auto; */
		border-radius: 50%;
		color: white;
		width: 35px;
		height: 35px;
		cursor: pointer;
		position: relative;
		display: inline-block;
	}

		.product__color .product__color_text::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	.product__color:hover .product__color_text {
		visibility: visible;
		opacity: .7;
	}

		.product__caption {
		margin: 25px auto 20px 0;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	/*.product__price {*/
	/*	margin: auto auto auto 0;*/
	/*	font-style: normal;*/
	/*	font-weight: 500;*/
	/*	font-size: 40px;*/
	/*	line-height: 110%;*/
	/*	letter-spacing: 0.02em;*/
	/*	color: #000000;*/
	/*}*/

	.product__price-old {
		font-size: 40px;
	}

	.product__price-new {
		font-size: 40px;
	}

	.product__name {
		font-style: normal;
		font-weight: 500;
		font-size: 55px;
		line-height: 110%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__descriptiontext {
		margin: auto 0;
		max-width: 378px;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__contact {
		margin: 25px auto 25px auto;
		width: 268px;
		height: 68px;
		background: #6E9C9F;
		font-style: normal;
		font-weight: 400;
		font-size: 17px;
		line-height: 138.9%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		cursor: pointer;
		border: 1px solid #6E9C9F;
	}

	.product__contact:hover {
		color: #6E9C9F;
		background: none;
	}

	/*слайдер*/
	.product__slider {
		/* margin: 75px auto auto auto; */
		max-width: 536px;
		max-height: 729px;
		overflow: hidden;
	}

	.product__middle {
		position: sticky;
	}

	.product__navigation {
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
	}

	.product__bar {
		width: 30px;
		height: 8px;
		margin: 6px;
		cursor: pointer;
		background-color: #6E9C9F;
		border-radius: 10px;
		transition: all 0.4s ease;
	}

	.product__img {
		max-width: 483px;
		max-height: 644px;
		object-fit: contain;
	}
}

@media screen and (min-width: 1280px) {
	.product {
		margin: auto;
		display: flex;
		flex-direction: row;
		max-width: 1107px;
		min-height: 729px;
	}

	.product__title {
		margin: auto auto auto 74px;
		display: flex;
		flex-direction: column;
		min-width: 347px;
		min-height: 800px;
	}

	.product__sizes {
		display: flex;
		flex-direction: row;
		max-width: 70%;
	}

	.product__size {
		/* margin: auto; */
		/* border: 1px solid black; */
		/* color: black; */
		/* background-color: white; */
		/* cursor: pointer; */
	}

	.product__size:hover {
		/* color: white; */
		/* background-color: black; */
	}

	.product__color_text {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.product__color {
		/* margin: auto; */
		border-radius: 50%;
		color: white;
		width: 35px;
		height: 35px;
		cursor: pointer;
		position: relative;
		display: inline-block;
	}

	.product__color .product__color_text::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	.product__color:hover .product__color_text {
		visibility: visible;
		opacity: .7;
	}

	.product__caption {
		margin: 40px auto 35px 0;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__price-old {
		font-size: 40px;
	}

	.product__price-new {
		font-size: 40px;
	}

	.product__name {
		font-style: normal;
		font-weight: 500;
		font-size: 55px;
		line-height: 110%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__descriptiontext {
		margin: auto 0;
		max-width: 490px;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #000000;
	}

	.product__contact {
		margin: 25px auto 25px auto;
		width: 268px;
		height: 68px;
		background: #6E9C9F;
		font-style: normal;
		font-weight: 400;
		font-size: 17px;
		line-height: 138.9%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #FFFFFF;
		cursor: pointer;
		border: 1px solid #6E9C9F;
	}

	.product__contact:hover {
		color: #6E9C9F;
		background: none;
	}

	/*слайдер*/
	.product__slider {
		/* margin: 75px auto auto auto; */
		max-width: 536px;
		max-height: 729px;
		overflow: hidden;
		/*border: 10px solid #000;*/
	}

	.product__middle {
		position: sticky;
		/*top: 50%;*/
		/*left: 50%;*/
		/*transform: translate(-50%, -50%);*/
	}

	.product__navigation {
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
	}

	.product__bar {
		width: 30px;
		height: 8px;
		margin: 6px;
		cursor: pointer;
		background-color: #6E9C9F;
		/*opacity: .5;*/
		border-radius: 10px;
		transition: all 0.4s ease;
	}

	.product__img {
		max-width: 536px;
		max-height: 729px;
		object-fit: contain;
	}

	#r1:checked ~ .s1 {
		margin-left: 0;
	}

	#r2:checked ~ .s1 {
		margin-left: -25%;
	}

	#r3:checked ~ .s1 {
		margin-left: -50%;
	}

	#r4:checked ~ .s1 {
		margin-left: -75%;
	}

	#r5:checked ~ .s1 {
		margin-left: -100%;
	}

	#r6:checked ~ .s1 {
		margin-left: -125%;
	}
}