.cardlist {
	margin: 0 auto;
	display: grid;
	justify-content: center;
	justify-items: center;
	grid-template-columns: repeat(2, 1fr);
		/* По умолчанию 2 карточки в линию */
}

@media (min-width: 320px) {
	.cardlist {
		max-width: 300px;
		/* min-height: 2724px; */
		margin: 64px auto auto auto;
		gap: 12px;
		grid-template-rows: repeat(9, auto);
		/* grid-template-columns: repeat(2, auto); */
	}
}

@media (min-width: 768px) {
	.cardlist {
		min-width: 700px;
		/* min-height: 1664px; */
		margin-top: 88px;
		gap: 16px;
		grid-template-rows: repeat(5, auto);
		grid-template-columns: repeat(3, 1fr);
			/* На больших экранах 3 карточки в линию */
		/* grid-template-columns: repeat(3, auto); */
	}
}

@media (min-width: 1024px) {
	.cardlist {
		min-width: 928px;
		/* min-height: 942px; */
		margin-top: 92px;
		gap: 14px;
		grid-template-rows: repeat(3, auto);
		/* grid-template-columns: repeat(4, 1fr); */
			/* На очень больших экранах 4 карточки в линию */
		/* grid-template-columns: repeat(3, auto); */
	}
}

@media (min-width: 1280px) {
	.cardlist {
		min-width: 1110px;
		/* min-height: 671px; */
		gap: 16px;
		grid-template-rows: repeat(3, auto);
		/* grid-template-columns: repeat(5, auto); */
	}
}