.shipping {
	display: flex;
	flex-direction: column;
}

.shipping__sale {
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: 0.02em;
	color: #000000;
}

.shipping__country {
	display: flex;
	flex-direction: row;
	max-width: 50%;
}

.shipping__city {
	max-width: 50%;
	display: flex;
	flex-direction: row;
}

@media screen and (min-width: 320px) {
	.shipping {
		max-width: 320px;
		min-height: 320px;
		margin: 25px auto 15px auto;
	}

	.shipping__sale {
		font-size: 15px;
		margin: 30px 10px auto 10px;
		width: 80%;
	}

	.shipping__country {
		font-size: 13px;
		max-width: 100%;
		margin: 30px 10px auto 10px;
	}

	.shipping__city {
		font-size: 13px;
		max-width: 100%;
		margin: 30px 10px auto 10px;
	}
}

@media screen and (min-width: 768px) {
	.shipping {
		max-width: 700px;
		min-height: 474px;
	}

	.shipping__sale {
		font-size: 17px;
		margin: 50px auto auto 0;
		width: 60%;
	}

	.shipping__country {
		margin: auto 0 auto auto;
	}

	.shipping__city {
		margin: 50px auto auto 0;
	}
}

@media screen and (min-width: 1024px) {
	.shipping {
		max-width: 880px;
		min-height: 474px;
	}
}

@media screen and (min-width: 1280px) {
	.shipping {
		max-width: 1110px;
		min-height: 474px;
	}
}