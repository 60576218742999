.info {
	margin: 75px auto;
}

.table {
	display: flex;
	margin: auto;
	padding: 0;
	justify-content: space-between;
	list-style-type: none;
	align-items: flex-start;
}

.table__text {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
	margin: 0;
	letter-spacing: 0.02em;
	color: #000000;
}

.table__heading {
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 140%;
	margin: 0;
	letter-spacing: 0.02em;
	color: #000000;
}

.table__cell {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-self: flex-start;
	align-items: flex-start;
}

.message {
	margin:  0 auto 25px;
	display: flex;
	flex-direction: column;
}

.message__heading {
	margin: auto;
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
}

.form {
	margin: 30px auto auto 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.form__input {
	outline: none;
	padding: 0;
	border: 0;
	border-bottom: 1px solid #000000;
	margin: 15px auto auto auto;
}

.form__button {
	margin: 0 auto auto 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 22px 50px;
	gap: 10px;

	background: #6E9C9F;
	flex: none;
	order: 4;
	flex-grow: 0;
	border: 1px solid #6E9C9F;
	cursor: pointer;

	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 138.9%;
	text-align: center;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}

.form__button:hover {
	color: #6E9C9F;
	background: none;
}

.map {
	margin: 75px auto auto auto;
	overflow: hidden;
}

.map__location {
	margin: auto;
}

@media screen and (min-width: 320px) {
	.info {
		max-width: 320px;
		min-height: 250px;
		margin-bottom: 0;
	}

	.table {
		flex-direction: column;
	}

	.table__text {
		min-width: 173px;
		min-height: 28px;
	}

	.table__heading {
		max-width: 73px;
		max-height: 24px;
	}

	.table__cell {
		min-width: 173px;
		min-height: 52px;
		margin-bottom: 20px;
		margin-left: 25px;
	}

	.message {
		max-width: 320px;
		min-height: 160px;
	}

	.message__heading {
		margin-left: 25px;
	}

	.form {
		margin-left: 25px;
	}

	.form__button {
		max-width: 189px;
		max-height: 68px;
	}

	.map {
		max-width: 320px;
		min-height: 320px;
	}

	.map__location {
		width: 320px;
		height: 320px;
	}
}

@media screen and (min-width: 768px) {
	.info {
		max-width: 700px;
		min-height: 72px;
	}

	.table {
		flex-direction: row;
	}

	.table__cell {
		margin-left: 0;
	}

	.message {
		max-width: 700px;
		min-height: 72px;
	}

	.message__heading {
		margin-left: 0;
	}

	.form {
		margin-left: 0;
	}

	.map {
		max-width: 700px;
		min-height: 474px;
	}

	.map__location {
		width: 700px;
		height: 474px;
	}
}

@media screen and (min-width: 1024px) {
	.info {
		max-width: 880px;
	}

	.message {
		max-width: 880px;
	}

	.map {
		max-width: 880px;
		min-height: 474px;
	}

	.map__location {
		width: 880px;
		height: 474px;
	}
}

@media screen and (min-width: 1280px) {
	.info {
		max-width: 1110px;
	}

	.message {
		max-width: 1110px;
	}

	.map {
		max-width: 1110px;
		min-height: 474px;
	}

	.map__location {
		width: 1110px;
		height: 474px;
	}
}