.cards__item {
	margin: auto;
}

.cards__name {
	margin: auto 0;
	font-style: normal;
	/*font-weight: 400;*/
	font-weight: bold;
	font-size: 20px;
	line-height: 140%;
	object-fit: cover;
	color: black;
	min-width: 66px;
	position: relative;
	text-align: right;
}

.cards__price {
	margin: auto auto 25px auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	object-fit: cover;
	/* position: relative; */
	/* z-index: 5; */
	cursor: default;
	align-items: center;
	/* padding: 8px 16px; */
}

.cards__price-name {
	margin: 5px auto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
}

.cards__price-new {
	margin: 0 auto 0 0;
	font-style: normal;
	/*font-weight: 400;*/
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	object-fit: cover;
	color: black;
	min-width: 66px;
	position: relative;
	text-align: left;
}

.cards__price-old {
	margin: auto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	color: black;
	position: relative;
	min-width: 66px;
	text-align: right;
	text-decoration: line-through;
	z-index: 5;
	opacity: .5;
}

.cards__img-item {
	margin: auto;
	object-fit: cover;
	display: inline-block;
	overflow: hidden; /* Скрываем всё за контуром */
}

.cards__img {
	display: block;
	width: 100%;
	/* Задайте фиксированную ширину, например, 100% */
	height: auto;
	/* Автоматическая высота для сохранения пропорций */
	transition: transform 0.3s ease;
}

@media (min-width: 320px) {
	.cards__item {
		width: 140px;
		height: 290px;
	}

	.cards__img-item {
		width: 140px;
		height: 192px;
	}
}

@media (min-width: 768px) {
	.cards__item {
		width: 222px;
		height: 390px;
	}

	.cards__img-item {
		width: 222px;
		height: 304px;
	}
}

@media (min-width: 1024px) {
	.cards__item {
		width: 300px;
	}

	.cards__img-item {
		width: 300px;
	}

	.cards__price-name {
		font-size: 17px;
	}

	.cards__price-new {
		font-size: 17px;
	}

	.cards__price-old {
		font-size: 17px;
	}
}

@media (min-width: 1280px) {
	.cards__item {
		width: 350px;
		height: 490px;
	}

	.cards__img-item {
		width: 350px;
		height: 400px;
	}
}

/*Картинка у карточки*/

/* .cards__img { */
	/* --g: 5px;   the gap */
	/* --s: 350px; the size */

	/* display: grid; */
	/* border-radius: 50%; */
/* } */
/* .cards__img > img { */
	/* grid-area: 1/1; */
	/* width: 300px; */
	/* aspect-ratio: 1; */
	/* object-fit: cover; */
	/* border-radius: 50%; */
	/* transform: translate(var(--_x,0),var(--_y,0)); */
	/* cursor: pointer; */
	/* z-index: 0; */
	/* transition: .3s, z-index 0s .3s; */
/* } */
/* .cards__img img:hover { */
	/* --_i: 1; */
	/* z-index: 1; */
	/* transition: transform .2s, clip-path .3s .2s, z-index 0s; */
/* } */
/* .cards__img:hover img { */
	/* transform: translate(0,0); */
/* } */
/* .cards__img > img:nth-child(1) { */
	/* clip-path: polygon(50% 50%,calc(50%*var(--_i,0)) calc(120%*var(--_i,0)),0 calc(100%*var(--_i,0)),0 0,100% 0,100% calc(100%*var(--_i,0)),calc(100% - 50%*var(--_i,0)) calc(120%*var(--_i,0))); */
	/* --_y: calc(-1*var(--g)) */
/* } */
/* .cards__img > img:nth-child(2) { */
	/* clip-path: polygon(50% 50%,calc(100% - 120%*var(--_i,0)) calc(50%*var(--_i,0)),calc(100% - 100%*var(--_i,0)) 0,100% 0,100% 100%,calc(100% - 100%*var(--_i,0)) 100%,calc(100% - 120%*var(--_i,0)) calc(100% - 50%*var(--_i,0))); */
	/* --_x: var(--g) */
/* } */
/* .cards__img > img:nth-child(3) { */
	/* clip-path: polygon(50% 50%,calc(100% - 50%*var(--_i,0)) calc(100% - 120%*var(--_i,0)),100% calc(100% - 120%*var(--_i,0)),100% 100%,0 100%,0 calc(100% - 100%*var(--_i,0)),calc(50%*var(--_i,0)) calc(100% - 120%*var(--_i,0))); */
	/* --_y: var(--g) */
/* } */
/* .cards__img > img:nth-child(4) { */
	/* clip-path: polygon(50% 50%,calc(120%*var(--_i,0)) calc(50%*var(--_i,0)),calc(100%*var(--_i,0)) 0,0 0,0 100%,calc(100%*var(--_i,0)) 100%,calc(120%*var(--_i,0)) calc(100% - 50%*var(--_i,0))); */
	/* --_x: calc(-1*var(--g)) */
/* } */

/*body {*/
/*	margin: 0;*/
/*	min-height: 100vh;*/
/*	display: grid;*/
/*	place-content: center;*/
/*	!*background: #3B8686;*!*/
/*}*/