.footer {
	display: flex;
	align-items: center;
	margin: auto;
}

.footer__copyright {
	margin: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
	cursor: default;
}

.footer__create-text {
	/*margin: 30px auto 0 0;*/
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
	cursor: default;
}

.footer__create {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
	cursor: pointer;
	text-decoration: none;
}

.footer__create:hover {
	color: #6E9C9F;
	opacity: .9;
}

.footer__column-links {
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.footer__link-insta {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/instagram1.png) no-repeat;
	border: 0;
}

.footer__link-insta:hover {
	background: url(../../images/instagram.png) no-repeat;
}

.footer__link-phone {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/phone1.png) no-repeat;
	border: 0;
}

.footer__link-phone:hover {
	background: url(../../images/phone.png) no-repeat;
}

.footer__link-telegram {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/telegram1.png) no-repeat;
	border: 0;
}

.footer__link-telegram:hover {
	background: url(../../images/telegram.png) no-repeat;
}

.footer__link-vk {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/vk.png) no-repeat;
	border: 0;
}

.footer__link-vk:hover {
	background: url(../../images/vk1.png) no-repeat;
}

.footer__link-whatsapp {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/whatsapp2.png) no-repeat;
	border: 0;
}

.footer__link-whatsapp:hover {
	background: url(../../images/whatsapp.png) no-repeat;
}

.footer__link-pay {
	margin: auto 9px auto 9px;
	width: 27px;
	height: 27px;
	background: url(../../images/pay.png) no-repeat;
	border: 0;
}

.footer__link-pay:hover {
	background: url(../../images/pay1.png) no-repeat;
}

@media screen and (min-width: 320px) {
	.footer {
		max-width: 320px;
		min-height: 100px;
		flex-direction: column;
		justify-content: space-around;
		flex-flow: wrap;
	}

	.footer__column-links {
		max-width: 300px;
		min-height: 21px;
	}

	.footer__link-insta {
		max-width: 75px;
		min-height: 21px;
	}

	.footer__link-phone {
		max-width: 75px;
		min-height: 21px;
	}

	.footer__link-telegram {
		max-width: 75px;
		min-height: 21px;
	}

	.footer__link-vk {
		max-width: 75px;
		min-height: 21px;
	}

	.footer__link-whatsapp {
		max-width: 75px;
		min-height: 21px;
	}
}

@media screen and (min-width: 768px) {
	.footer {
		min-width: 700px;
		flex-direction: row;
		justify-content: space-between;
		flex-flow: row-reverse;
	}
}

@media screen and (min-width: 1024px) {
	.footer {
		min-width: 880px;
	}
}

@media screen and (min-width: 1280px) {
	.footer {
		min-width: 1110px;
		max-height: 100px;
	}
}